import React, { useContext } from "react";

import { Footer } from "../../components/Footer/Footer";
import { InspirationPostPageContext } from "../../helpers/pagesContext";
import { Body } from "./components/Body/Body";
import { Hero } from "./components/Hero/Hero";
import * as styles from "./Post.module.css";

export default function Post() {
  const {
    inspirationPost: { node_locale },
  } = useContext(InspirationPostPageContext);

  return (
    <>
      <Hero />
      <Body />
      <div className={styles.footer}>
        <Footer
          background={`linear-gradient(206.5deg, rgb(149 109 81 / 8%) -14.94%, rgb(42 59 62 / 48%) 88.71%)`}
          lang={node_locale}
        />
      </div>
    </>
  );
}
