import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { InspirationPostPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Hero.module.css";

export const Hero = () => {
  const {
    inspirationPost: { thumbnail, title, subtitle, author, time, createdAt },
  } = useContext(InspirationPostPageContext);

  return (
    <section className={styles.root}>
      <GatsbyImage
        className={styles.image}
        image={thumbnail.gatsbyImageData}
        alt={title}
      />
      <div className={styles.overlay} />
      <div className={styles.container}>
        <div className={styles.subtitle}>Article</div>
        <SlipTransition
          lines={[title]}
          color="#fff"
          important={true}
          options={{ delay: 0.4, animateY: -150 }}
        />
        {subtitle && (
          <SlideTransition delay={0.4}>
            <div className={styles.subtitleUnder}>{subtitle}</div>
          </SlideTransition>
        )}
        <SlideTransition delay={0.4}>
          <div className={styles.infos}>
            <div>{createdAt}</div>
            <StaticImage
              src="../../../../resources/images/actuality-content/vector.png"
              alt="vector"
              width={5}
            />
            <div>{author}</div>
            <StaticImage
              src="../../../../resources/images/actuality-content/vector.png"
              alt="vector"
              width={5}
            />
            <div>{time}</div>
          </div>
        </SlideTransition>
      </div>
    </section>
  );
};
