import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";

import { FormattedText } from "../../../../components/FormattedText/FormattedText";
import { InspirationFooterFocus } from "../../../../components/Inspiration/components/InspirationFooter/InspirationFooterFocus";
import { InspirationPost } from "../../../../components/Inspiration/Inspiration";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { InspirationPostPageContext } from "../../../../helpers/pagesContext";
import { INSPIRATIONS } from "../../../../helpers/route";
import * as styles from "./Body.module.css";

export const Body = () => {
  const {
    inspirationPost: { id, content, author, authorPicture, authorJob, url },
    resultLastInspirationPost,
    inspirationContainer: { goingBack, keepExploring, otherPost },
  } = useContext(InspirationPostPageContext);

  return (
    <section className={styles.watermark}>
      <div className={styles.back}>
        <Link to={localeLink(INSPIRATIONS)}>
          <StaticImage
            src="../../../../resources/images/article/arrow.png"
            alt="arrow"
            width={33}
          />
          <span>{goingBack}</span>
        </Link>
      </div>
      <SlideTransition delay={0.4}>
        <div className={styles.root}>
          <div className={styles.container}>
            <FormattedText content={content} />
            <InspirationFooterFocus
              author={{ name: author, picture: authorPicture, job: authorJob }}
              id={id}
              url={url}
            />
          </div>
        </div>
      </SlideTransition>
      <div className={styles.moreMedia}>
        <p>{keepExploring}</p>
        <h3>{otherPost}</h3>
      </div>
      <div className={styles.cardMedias}>
        {resultLastInspirationPost.map(
          ({
            node: {
              url,
              id,
              author,
              createdAt,
              title,
              subtitle,
              content,
              thumbnail,
              time,
            },
          }) => (
            <InspirationPost
              key={id}
              {...{
                url,
                id,
                author,
                createdAt,
                title,
                subtitle,
                content,
                thumbnail,
                time,
                delay: 0.2,
              }}
            />
          )
        )}
      </div>
    </section>
  );
};
